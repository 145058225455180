<template lang="html">
    <div id="login">
        <div class="bg">
            <p>新坐标后台管理系统</p>
            <div class="loginBox" @keyup.enter="loginClick">
                <p>用户登录</p>
                <input class="user" type="text" v-model="name" placeholder="请输入用户名">
                <input class="pwd" type="password" v-model="pwd" placeholder="请输入密码">
                <div class="code">
                    <input type="text" v-model="yzm" placeholder="请输入验证码">
                    <img :src="captchaSrc" @click="flushCaptcha" />
                </div>
                <button @click="loginClick()">登录</button>
            </div>
            <h4>技术支持&copy;云翼信息技术有限公司</h4>
        </div>
    </div>
</template>

<script>
export default {
    data: function() {
        return {
            name: '',
            pwd: '',
            yzm:'',
            captchaSrc:this.domain + '/captcha'
        }
    },
    methods: {
        flushCaptcha: function() {
            this.captchaSrc =this.domain + '/captcha' + '?' + Math.random()
        },
        loginClick: function() {
            if (!this.name) {
                this.$message.error('请输入用户名!');
            } else if (!this.pwd) {
                this.$message.error('请输入密码!');
            } else if (!this.yzm) {
                this.$message.error('请输入验证码!');
            } else {
                this.http.post('/admin.login/login', {
                    username: this.name,
                    password: this.$md5(this.pwd),
                    yzm:this.yzm
                }).then((re) => {
                    this.$router.push('/overview');
                    // this.http.post(this.domain + '/user/getUserInfo').then(re => {
                        this.$set(this.$root, 'userInfo', re);
                        sessionStorage.setItem('userId',re.id);
                    // })
                })
            }
        }
    }
}
</script>

<style lang="css" scoped>
</style>
